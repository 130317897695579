//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mdiBrightness6 } from '@mdi/js'
export default {
  props: {
    pages: {
      type: Array,
      required: true,
    },
    links: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      mdiBrightness6,
    }
  },
  methods: {
    toggleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    },
  },
}
