//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mdiHome,
  mdiGithub,
  mdiLinkedin,
  mdiEmail,
  mdiInformation,
  mdiApplicationBrackets,
} from '@mdi/js'
export default {
  data() {
    return {
      mdiHome,
      pages: [
        {
          icon: mdiInformation,
          title: 'About me',
          to: '/#about',
        },
        {
          icon: mdiApplicationBrackets,
          title: 'Projects',
          to: '/#projects',
        },
        {
          icon: mdiEmail,
          title: 'Contact',
          to: '/#contact',
        },
      ],
      links: [
        {
          icon: mdiGithub,
          title: 'Discover my GitHub profile',
          to: 'https://github.com/mtdvlpr',
        },
        {
          icon: mdiLinkedin,
          title: 'Check me out on LinkedIn',
          to: 'https://www.linkedin.com/in/manoaht/',
        },
      ],
    }
  },
  computed: {
    color() {
      return this.$vuetify.theme.dark ? 'white' : 'black'
    },
  },
}
